import PropTypes from 'prop-types';
import useNearScreen from '@/hooks/useNearScreen';
import Skeleton from '@/components/atoms/Skeleton';

const LazyComponent = ({
  children,
  distance,
  skeleton,
  skeletonWidth,
  skeletonHeight
}) => {
  const { isNearScreen, fromRef } = useNearScreen(distance);
  const renderSkeletons = () => {
    if (Array.isArray(children)) {
      return children.map((c, i) => (
        <Skeleton
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton${i}`}
          width={skeletonWidth}
          height={skeletonHeight}
          type={skeleton}
          dark
        />
      ));
    }
    return (
      <Skeleton
        width={skeletonWidth}
        height={skeletonHeight}
        type={skeleton}
        dark
      />
    );
  };
  return skeleton === 'productCard' ? (
    <li
      style={{ width: '100%' }}
      ref={fromRef}
    >
      {isNearScreen ? children : renderSkeletons()}
    </li>
  ) : (
    <div
      ref={fromRef}
      style={{ width: '100%' }}
    >
      {isNearScreen ? children : renderSkeletons()}
    </div>
  );
};

LazyComponent.propTypes = {
  children: PropTypes.node.isRequired,
  distance: PropTypes.string,
  skeleton: PropTypes.oneOf(['productCard']),
  skeletonWidth: PropTypes.string,
  skeletonHeight: PropTypes.string
};
LazyComponent.defaultProps = {
  distance: '100px',
  skeleton: null,
  skeletonWidth: '200px',
  skeletonHeight: '200px'
};
export default LazyComponent;
